exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-cheatsheet-ds-cheatsheet-ds-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/cheatsheet-ds/cheatsheet-ds.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-cheatsheet-ds-cheatsheet-ds-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-clean-code-clean-code-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/clean-code/clean-code.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-clean-code-clean-code-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-ddia-ddia-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/ddia/ddia.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-ddia-ddia-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-deployment-ci-deployment-ci-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/deployment-ci/deployment-ci.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-deployment-ci-deployment-ci-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-development-continuous-development-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/development/continuous-development.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-development-continuous-development-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-docker-docker-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/docker/docker.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-docker-docker-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-first-post-first-post-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/first-post/first-post.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-first-post-first-post-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-inc-icpc-jakarta-2019-inc-icpc-jakarta-2019-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/inc-icpc-jakarta-2019/inc-icpc-jakarta-2019.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-inc-icpc-jakarta-2019-inc-icpc-jakarta-2019-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-people-person-people-person-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/people-person/people-person.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-people-person-people-person-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-quickselect-quickselect-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/quickselect/quickselect.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-quickselect-quickselect-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-skripsi-skripsi-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/skripsi/skripsi.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-skripsi-skripsi-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-tdd-tdd-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/tdd/tdd.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-tdd-tdd-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-team-dyanmics-team-dynamics-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/team-dyanmics/team-dynamics.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-team-dyanmics-team-dynamics-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-team-name-competitive-programming-team-name-competitive-programming-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/team-name-competitive-programming/team-name-competitive-programming.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-team-name-competitive-programming-team-name-competitive-programming-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-posts-user-centered-design-user-centered-design-mdx": () => import("./../../../src/templates/PostTemplate.tsx?__contentFilePath=/vercel/path0/src/posts/user-centered-design/user-centered-design.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-posts-user-centered-design-user-centered-design-mdx" */)
}

